import React, { useState } from 'react'
import Layout from '../components/Layout'
import { useCart } from '../lib/CartContext'
import axios from 'axios'
import SEO from '../components/Seo'
import Img from 'gatsby-image'
import { celularMask } from '../lib/mask.js'
import '../assets/cart.style.css'

const Checkout = () => {
  const cart = useCart()

  const remove = slug => () => {
    cart.removeFromCart(slug)
  }

  const addToCart = (slug, quantity) => () => {
    cart.addToCart({ slug }, quantity)
  }

  const continuarNavegando = () => {
    if (typeof window !== `undefined`) window.location.replace(`/produtos`)
  }

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [cidadeEstado, setCidadeEstado] = useState('')
  const [celular, setCelular] = useState('')
  const [mensagem, setMensagem] = useState('')
  const [successMail, setSuccessMail] = useState(false)

  const onChangeName = e => {
    setName(e.target.value)
  }
  const onChangeEmail = e => {
    setEmail(e.target.value)
  }
  const onChangeCidadeEstado = e => {
    setCidadeEstado(e.target.value)
  }
  const onChangeCelular = e => {
    setCelular(celularMask(e.target.value))
  }
  const onChangeMensagem = e => {
    setMensagem(e.target.value)
  }

  const [nameDefault, setNameDefault] = useState('hidden ')
  const [emailDefault, setEmailDefault] = useState('hidden ')
  const [cidadeEstadoDefault, setCidadeEstadoDefault] = useState('hidden ')
  const [celularDefault, setCelularDefault] = useState('hidden ')
  const [mensagemDefault, setMensagemDefault] = useState('hidden ')
  const [btnDefault, setbtnDefault] = useState('hidden ')

  const [errorMessageName, setErrorMessageName] = useState('')
  const [errorMessageEmail, setErrorMessageEmail] = useState('')
  const [errorMessageCidadeEstado, setErrorMessageCidadeEstado] = useState('')
  const [errorMessageCelular, setErrorMessageCelular] = useState('')
  const [errorMessageMensagem, setErrorMessageMensagem] = useState('')
  const [errorMessageBtn, setErrorMessageBtn] = useState('')

  const validateEmail = email => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const verifyErrors = () => {
    setNameDefault('hidden ')
    setEmailDefault('hidden ')
    setCidadeEstadoDefault('hidden ')
    setCelularDefault('hidden ')
    setMensagemDefault('hidden ')
    setbtnDefault('hidden ')
    let noErrors=true
    if (name === '') {
      noErrors=false
      setErrorMessageName('Nome é um campo obrigatório.')
      setNameDefault('')
    } else if (!name.includes(' ') || name.split(' ')[1] === '') {
      noErrors=false
      setErrorMessageName('Nome inválido.')
      setNameDefault('')
    }
    if (email === '') {
      noErrors=false
      setErrorMessageEmail('E-mail é um campo obrigatório.')
      setEmailDefault('')
    } else if (!validateEmail(email)) {
      noErrors=false
      setErrorMessageEmail('E-mail inválido.')
      setEmailDefault('')
    }
    if (cidadeEstado === '') {
      noErrors=false
      setErrorMessageCidadeEstado('Cidade/Estado é um campo obrigatório.')
      setCidadeEstadoDefault('')
    } else if (!cidadeEstado.includes('/') || cidadeEstado.split('/')[1].length < 2) {
      noErrors=false
      setErrorMessageCidadeEstado('Cidade/Estado inválidos.')
      setCidadeEstadoDefault('')
    }
    if (celular === '') {
      noErrors=false
      setErrorMessageCelular('Celular é um campo obrigatório.')
      setCelularDefault('')
    } else if (celular.length < 15) {
      noErrors=false
      setErrorMessageCelular('Número de celular inválido.')
      setCelularDefault('')
    }
    if (mensagem === '') {
      noErrors=false
      setErrorMessageMensagem('Mensagem é um campo obrigatório.')
      setMensagemDefault('')
    }
    return noErrors
  }

  const showBtnError = () => {
    setErrorMessageBtn('Preencha os dados corretamente!')
  }

  const handleSubmit = () => {
    if (verifyErrors()){
      send()
    } else {
      showBtnError()
      setbtnDefault('')
    }
  }

  const send = async() => {
    await axios.post('/.netlify/functions/send', {
      from: 'carrinho',
      name,
      email,
      cidadeEstado,
      celular,
      mensagem,
      products: cart.cart
    })
    setSuccessMail(true)
    cart.cleanCart()
    console.log('email enviado')
    smoothScrollBackToTop()
  }

  function smoothScrollBackToTop() {
    const targetPosition = 0;
    const startPosition = (typeof window !== `undefined`) ? window.pageYOffset : 0;
    const distance = targetPosition - startPosition;
    const duration = 300;
    let start = null;

    if (typeof window !== `undefined`) window.requestAnimationFrame(step)

    function step(timestamp) {
      if (!start) start = timestamp
      const progress = timestamp - start
      if (typeof window !== `undefined`) window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration))
      if (progress < duration)
        if (typeof window !== `undefined`) window.requestAnimationFrame(step)
    }
    return true
  }

  function easeInOutCubic(t, b, c, d) {
    t /= d/2
    if (t < 1) return c/2*t*t*t + b
    t -= 2
    return c/2*(t*t*t + 2) + b
  }

  return (
    <Layout>
      <SEO description='Adicione produtos ao carrinho para poder pedir um orçamento.' />
      <h1 className='invisible'>Pedido de orçamento</h1>

      {
        // Mensagem indicando carrinho vazio
        !successMail && cart.size === 0 && smoothScrollBackToTop() &&
        <div>
          <div className='bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4' role='alert'>
            <p className='font-bold'>Atenção</p>
            <p>Para pedidos de orçamento, adicione produtos.</p>
          </div>
          <div className='flex items-center mt-5 w-full'>
            <button className='mx-auto bg-gray-800 hover:bg-gray-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-12 ' style={{fontSize:'12px'}} onClick={continuarNavegando}>
              VOLTAR PARA OS PRODUTOS
            </button>
          </div>
        </div>
      }
      {
        // Carrinho
        !successMail &&
        <div className='formulario flex flex-col md:flex-row bg-white shadow mb-10'>
          {
            cart.size > 0 &&
            <div className='flex flex-col md:flex-row'>
              <div className='order-2 md:order-1 flex flex-col px-8 pt-6'>
                <div className='robika flex items-center justify-center text-gray-700 font-bold text-base md:text-lg pb-2'>
                  PRODUTOS PARA ORÇAMENTO
                </div>
                {
                  Object
                  .keys(cart.cart)
                  .map(slug => {
                    const product = cart.cart[slug]
                    return (
                      <div key={slug} className='item'>
                        <div className='buttons'>
                          <span className='delete-btn' onClick={remove(slug)}></span>
                          <span className='like-btn'></span>
                        </div>

                        <div className='image'>
                          <Img className='my-auto' fluid={product.images[0].fluid} alt={product.images[0].description} style={{maxHeight: '78px'}} />
                        </div>

                        <div className='description'>
                          <span>{product.product}</span>
                        </div>

                        <div className='quantity'>
                          <button className='plus-btn' onClick={addToCart(slug, 1)} />
                          <input type='text' name='name' value={product.quantity} disabled={true} />
                          <button className='minus-btn' onClick={addToCart(slug, -1)} disabled={product.quantity <= 1} />
                        </div>
                      </div>
                    )
                  })
                }
              </div>

              <div className='order-1 md:order-2 px-8 pt-6 pb-8 mb-4'>
                <h1 className='robika font-bold w-full flex justify-center pb-5'>PEDIR ORÇAMENTO</h1>
                <div className='flex flex-wrap -mx-3 mb-4'>
                  <div className='w-full md:w-1/2 mb-4 md:mb-0 md:px-3'>
                    <label className='px-3 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='grid-first-name'>
                      Nome Completo
                    </label>
                    <input onChange={onChangeName} className='appearance-none block w-full shadow text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:border-gray-500' id='grid-first-name' type='text' placeholder='Insira seu nome aqui.' />
                    <p className={nameDefault + 'pl-2 text-red-500 text-xs italic'}>{errorMessageName}</p>
                  </div>
                  <div className='w-full md:w-1/2 md:mb-0 md:px-3'>
                    <label className='px-3 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='grid-email'>
                      E-mail
                    </label>
                    <input onChange={onChangeEmail} className='appearance-none block w-full shadow text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:border-gray-500' id='grid-email' type='text' placeholder='Insira seu email aqui.' />
                    <p className={emailDefault + 'pl-2 text-red-500 text-xs italic'}>{errorMessageEmail}</p>
                  </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-4'>
                  <div className='w-full md:w-1/2 mb-4 md:mb-0 md:px-3'>
                    <label className='px-3 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='grid-city'>
                      Cidade/Estado
                    </label>
                    <input onChange={onChangeCidadeEstado} className='appearance-none block w-full shadow text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:border-gray-500' id='grid-city' type='text' placeholder='Insira sua Cidade/UF aqui.' />
                    <p className={cidadeEstadoDefault + 'pl-2 text-red-500 text-xs italic'}>{errorMessageCidadeEstado}</p>
                  </div>
                  <div className='w-full md:w-1/2 mb-4 md:mb-0 md:px-3'>
                    <label className='px-3 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='grid-zip'>
                      Celular
                    </label>
                    <input onChange={onChangeCelular} value={celular} className='appearance-none block w-full shadow text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:border-gray-500' id='grid-zip' type='text' placeholder='(DDD) Número' />
                    <p className={celularDefault + 'pl-2 text-red-500 text-xs italic'}>{errorMessageCelular}</p>
                  </div>
                </div>
                <div className='flex flex-wrap -mx-3 mb-4'>
                  <div className='w-full md:mb-0 md:px-3'>
                    <label className='px-3 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='grid-message'>
                      Observações
                    </label>
                    <textarea onChange={onChangeMensagem} className='appearance-none block w-full shadow text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:border-gray-500' rows='5' type='text' placeholder='Digite suas observações aqui.' />
                    <p className={mensagemDefault + 'pl-2 text-red-500 text-xs italic'}>{errorMessageMensagem}</p>
                  </div>
                </div>
                <div className='flex flex-col items-center -mx-3 mt-6 justify-center'>
                  <p className={btnDefault + 'text-red-500 text-sm italic w-full text-center py-1'}>{errorMessageBtn}</p>
                  <div className='w-3/5 flex flex-col items-center'>
                    <button className='w-full bg-gray-900 hover:bg-gray-800 focus:shadow-outline focus:outline-none text-base text-white font-bold py-2 px-10 ' onClick={handleSubmit}>
                      ENVIAR
                    </button>
                    <button className='w-full mt-3 bg-gray-900 hover:bg-gray-800 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-12 ' style={{fontSize:'12px'}} onClick={continuarNavegando}>
                      VER MAIS PRODUTOS
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      }
      {
        // Mensagem de sucesso de envio do formulário
        successMail &&
        <div className='bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md' role='alert'>
          <div className='flex'>
            <div className='py-1'><svg className='fill-current h-6 w-6 text-teal-500 mr-4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z'/></svg></div>
            <div>
              <p className='font-bold'>Seu pedido de orçamento foi enviado com sucesso!</p>
              <p className='text-sm'>As respostas demoram em torno de 1 dia útil para serem processadas.</p>
            </div>
          </div>
        </div>
      }
    </Layout>
  )
}

export default Checkout
